import { createMuiTheme } from '@material-ui/core/styles';

const PaypsMuiTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#21caab",
      },
      secondary: {
        main: '#474747',
      },
    },
    overrides: {
        MuiTypography: {
          colorPrimary: {
            color: '#187D6E !important'
          },
          colorSecondary:{
              color: '#FFF !important'
          }
        },
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#187D6E',
          },
      },
      MuiPickersToolbarText:{
        toolbarTxt:{
          color: 'rgba(255, 255, 255, 0.54)',
        },
        toolbarBtnSelected:{
          color: 'white',
        }
      }
    }
  });

  export default PaypsMuiTheme;