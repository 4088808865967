import React, { Component } from 'react';
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import {checkUserPassword, validateEmail} from 'components/helpers/fieldValidator';
import { ThemeProvider } from "@material-ui/styles";
import PaypsMuiTheme from 'components/graphics/PaypsMuiTheme';



class LoginForm extends Component {
  constructor(props) {
    super();
    this.state = {
      newUser:props.newUser,
      errors:[],
      passwordShow: false,
      modal:props.modal
    };
  }

  onFieldChange = (event, type) => {
    const {newUser, errors} = this.state;
    //Dès saisie on masque la modal pour éviter de perturber le user
    this.hideModal();

    newUser[type] = event.target.value;
    switch(type){

      case "email":
        if(validateEmail(event.target.value) === false){
          errors['email'] = true;
        }else{
          delete(errors['email']);
        }
      break;

      case "password":
        if(checkUserPassword(event.target.value) === false){
          errors[type] = true;
        }else{
          delete(errors[type]);
        }
      break;

      default:
      break;
    }

    this.setState({newUser, errors});
  }

  togglePassword = (field) => {
    let {passwordShow} = this.state;
    passwordShow = passwordShow ? false : true;
    this.setState({passwordShow});
  }

  hideModal(){
    this.props.onError({message: "", displayType: "hidden"});
  }

  resetPassword = () =>{
    this.props.onPasswordReset();
  }


  submitForm = (pEvent) => {
    pEvent.preventDefault();
    const {newUser, errors} = this.state;

    let errorField = "";
    for(const key in errors){
      errorField = key;
      break;
    };

    if(Object.keys(errors).length > 0){
      const modal = {};
      let sMessageError = "";

      switch(errorField){
        case "email":
          sMessageError = "Votre email ne semble pas correct";
        break;

        case "password":
         sMessageError = "Le mot de passe doit faire au minimum 8 caractères, une majuscule, une minuscule et un chiffre. Seuls les caracères spéciaux @#%-_ sont acceptés";
        break;

        default:
        break;
      }
      modal.message= sMessageError;
      modal.displayType="error";
      this.setState(errors);
      this.props.onError(modal);
      return;
    }

    // On dispatch le user créé au composant appelant
    this.props.onSubmit(pEvent, newUser);
  }

  render() {
    const {passwordShow, newUser, errors} = this.state;
    return (
        <div className="formContainer">
          <form onSubmit={this.submitForm}>
            <ThemeProvider theme={PaypsMuiTheme}>
              <TextField
                  id="email"
                  label="Email"
                  required={true}
                  value={newUser.email} 
                  error={errors["email"]}
                  onChange={event => this.onFieldChange(event, "email")}
                  className="inputLine"
                  color="secondary"
                />

              <TextField
                  id="password"
                  label="Mot de passe"
                  required={true}
                  value={newUser.password} 
                  error={errors["password"]}
                  onChange={event => this.onFieldChange(event, "password")}
                  type={passwordShow ? "text" : "password"}
                  className="inputLine"
                  color="secondary"
                  InputProps={{
                    endAdornment:
                      <IconButton onClick={(event) => this.togglePassword()}>
                        { passwordShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    }}
                />
              <div className="forgotPassword" onClick={this.resetPassword}>Mot de passe oublié ?</div>
              <input type="submit" className="submitButton" value="Je me connecte" />
            </ThemeProvider>
          </form>
        </div>
    );
  }

  }

  LoginForm.propTypes = {
    newUser: PropTypes.shape({
      email: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired
    }),
    onSubmit:PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onPasswordReset: PropTypes.func.isRequired
  };

  export default LoginForm;