import React from 'react'
import PropTypes from 'prop-types'


const Modal = ({ message, displayType }) => (
    <div className="modal">
        <div className={displayType}>
         {message.split('\n').map((item, key) => {
          return <span key={key}>{item}<br/></span>
        })}
        </div>
    </div>
  )

  Modal.propTypes = {
    message: PropTypes.string.isRequired,
    displayType: PropTypes.oneOf([
      'error',
      'info',
      'hidden'
    ]).isRequired,
  }

export default Modal