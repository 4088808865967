import Validator from 'email-validator';

// Check userPassword is at least one lower case one uppercase and one number
export const checkUserPassword = (password) => {
    let regexP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w@#%-]{8,}$/;
    return regexP.test(password);
}

export const cleanUserInput = (inputValue) => {
    // On retire tous les caractères interdit pour affichage plus propre
    return inputValue.replace(/[.*+?!<>^${}()|[\]\\]/g, '');
}

export const checkZipCode = (zipCode) => {
    let regexP = /^[0-9]{5}$/;
    return regexP.test(zipCode);
}

export const validateEmail = (email) => {
    return Validator.validate(email);
}