import React from 'react';
import 'css/app.css';

import Login from 'components/scenes/Login';

function App() {
  return (
    <div className="paypsContainer">
      <div className="sceneContainer">
        <Login />
      </div>
    </div>
  );
}

export default App;
