import React, { Component } from 'react';
import { saveStorage } from 'components/helpers/storageManager';

import LoginForm from 'components/modules/form/LoginForm';
import Modal from'components/utils//Modal';
import userConnexion from 'components/connectors/userConnexion';
import { FacebookProvider, LoginButton } from 'react-facebook';

class Login extends Component {
    constructor(props) {
      super(props);
      this.state = {
        access_token:"",
        redirect_uri: "",
        newUser:{
            email:"",
            password:""
        },
        modal:{
            message: "",
            displayType: "hidden"
        }
      };
    }

    componentDidMount(){
        // On commence par checker que tout est OK dans les params reçus
        const searchParams = new URLSearchParams(window.location.search);
        if(!searchParams.has("access_token") || !searchParams.has("redirect_uri")){
            const error = {};
            error.message = "Paramètres incomplets";
            error.displayType = "error";
            this.showError(error);
        }

        const accessToken = searchParams.get("access_token");
        const redirectUri = searchParams.get("redirect_uri");
        const paypsStorage = {accessToken: accessToken};
        saveStorage(paypsStorage);
        this.setState({access_token: accessToken, redirect_uri: redirectUri});
    }

    passwordReset = () =>{
        window.open('https://www.payps.fr/ask-new-password');
    }

    responseFacebook = (response) => {
        console.log("Login on a une réponse de FB ", response);
        const userInfo = {provider:"facebook", token:response.tokenDetail.accessToken};
        const redirect_uri = this.state.redirect_uri;
        userConnexion.sociallLogin(userInfo, response)
        .then(
            response => {
                console.log("on a un retour du socialLogin ", response);
                window.location.replace(redirect_uri+"?userId="+response.uuid);
            },
            error => {
                //console.log("on récupère une erreur ", error.toString())
                const errorItem = {};
                errorItem.message = error.toString();
                errorItem.displayType = "error";
                this.showError(errorItem);
            }
        )
    }

    showError = (error) =>{
        const {modal} = this.state;
        modal.message = error.message;
        modal.displayType = error.displayType ? error.displayType : "error";
        this.setState(modal);
    }

    submitForm = (pEvent, pUser) =>{
        pEvent.preventDefault();
        pUser.redirect_uri = this.state.redirect_uri;
        userConnexion.login(pUser)
        .then(
            response => {
                window.location.replace(response.redirect_uri+"?userId="+response.userId);
            },
            error => {
                //console.log("on récupère une erreur ", error.toString())
                const errorItem = {};
                errorItem.message = error.toString();
                errorItem.displayType = "error";
                this.showError(errorItem);
            }
        )
    }

    render() {
        const {modal, newUser} = this.state;


        return(
            <div className= "paypsScene loginScene">
                <div className="logoHeader">
                    <img src={process.env.REACT_APP_IMAGES_HOST+"logoPayps.png"} className="paypsLogo"  alt="Payps"/>
                </div>
                <Modal message={modal.message} displayType={modal.displayType} />
                {
                    modal.displayType !== "error" &&
                    <div>
                        <div className="rsLogin">
                            <div className="centrageBouton">
                                <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_ID}>
                                    <LoginButton
                                    scope="public_profile,email"
                                    onCompleted={this.responseFacebook}
                                    onError={this.showError}
                                    >
                                        <div className="facebookLogin">Je m'identifie avec Facebook</div>
                                    </LoginButton>
                                </FacebookProvider>
                            </div>
                            <div className="subText">Ou je m'identifie avec mon e-mail</div>
                        </div>
                        <LoginForm newUser={newUser} 
                                onError={this.showError} 
                                onSubmit={this.submitForm} 
                                onPasswordReset={this.passwordReset}
                        />
                    </div>
                    
                }
            </div>
        );
    }
}

export default Login;