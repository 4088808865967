export const loadStorage = () => {
    try{
        const actualStorage = localStorage.getItem('payps-connect');
        if(actualStorage === null){
            return undefined;
        }
        return JSON.parse(actualStorage);
    }catch(error){
        return undefined;
    }
};


export const saveStorage = (storage) => {
    try{
        //On merge le localStorage existant avec les nouvelles valeurs passées
        const oldData = localStorage.getItem('payps-connect') ? JSON.parse(localStorage.getItem('payps-connect')) : {};
        const newStorage = {...oldData, ...storage};
        localStorage.setItem('payps-connect', JSON.stringify(newStorage));
        return true;
    }catch(error){
        console.log("impossible d'enregistrer le localStorage");
        return false;
    }
};

export const cleanStorage = () => {
    // On clean l'ancien Storage car plus utilisable
    localStorage.removeItem('payps-connect');
};
