import axios from 'axios';
import { saveStorage, loadStorage, cleanStorage } from 'components/helpers/storageManager';

class userConnexion {

    /*
    *Classic Login
    @Parameters 
    email -> string
    password -> string
    **/
   static async login(userData){
      const config = {
        headers: {'Authorization': "Bearer " + loadStorage().accessToken}
      };
        try{
            const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/authorize', userData, config);
            if(response.data.valide === 1){
              return Promise.resolve(response.data);
            }else{
              return Promise.reject(response.data.message);
            }
          }
          catch(error){
            return Promise.reject("Désolé, impossible d'identifier ce compte");
          }
    }

    /*
    *Social Login
    @Parameters 
    provider -> string /// Social network used to connect
    token -> string  /// Access_token provided by social network
    **/
    static async sociallLogin(userData){
      try{
          const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/social/loginByToken', userData);
          if(response.data.valide === 1){
            return Promise.resolve(response.data.userData);
          }else{
            return Promise.reject(response.data);
          }
        }
        catch(error){
          return Promise.reject(error.response.data);
        }
  }

  /*
    *Refresh User's access_token
    @Parameters 
    refreshToken -> string
    **/
  static async refresh(uuid, refreshToken){
    try{
        const tokenRefreshing = {tokenRefreshing: true};
        saveStorage(tokenRefreshing);
        const response = await axios.post(process.env.REACT_APP_API_PAYPS+'/user/'+uuid+'/refresh', {refreshToken : refreshToken});
        if(response.data.valide === 1){
          const actualStorage = loadStorage();
          if(actualStorage === undefined){
            return Promise.reject('Erreur impossible de récupérer le Storage');
          }
          const paypsStorage = {user : actualStorage.user, accessToken:response.data.auth_token, tokenRefreshing: false};
          saveStorage(paypsStorage);
          return Promise.resolve(response.data);
        }else{
          cleanStorage();
          return Promise.reject(response.data.message);
        }
      }
      catch(error){
        cleanStorage();
        return Promise.reject("Désolé, impossible de renouveller vore token");
      }
  }


  static logout(){
    try{
      cleanStorage();
      return Promise.resolve("Utilisateur déconnecté");
    }
    catch(error){
      return Promise.reject("Impossible de déconnecter l'utilisateur");
    }
  }


  static isLoggedIn(){
    const userData = loadStorage();
    if(userData && userData.user && userData.user.uuid){
      return true;
    }else{
      return false;
    }
  }

}

export default userConnexion;